import * as React from 'react';
import './App.styles.css';

export const App = () => (
    <React.Fragment>
        <div className="overlay"/>
        <div className="terminal">
            <h1>Machacek<span className="errorcode">.pro</span></h1>
            <p className="output">The page you are looking for might have been removed, had its name
                changed or is temporarily unavailable</p>
            <p className="output">Please try <a href="http://google.com">google</a></p>
            <p className="output">Good luck</p>
        </div>
    </React.Fragment>
)


export default App;
